import * as React from 'react'

import {
  Box,
  HStack,
  Icon,
  Image,
  Skeleton,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'

export type CartProductMetaProps = {
  name?: string
  image?: string
  quantity: number
  productTags?: {
    nodes?: [
      {
        name: string
      }
    ]
  }
}

export const CartProductMeta = (props: CartProductMetaProps) => {
  const { image, name, productTags, quantity } = props
  return (
    <Stack direction="row" spacing="5" width="full">
      <Image
        rounded="lg"
        width="120px"
        height="120px"
        fit="cover"
        src={image}
        alt={name}
        draggable="false"
        loading="lazy"
        fallback={<Skeleton />}
      />
      <Box pt="4">
        <Stack spacing="0.5">
          <Text fontWeight="bold">{name} </Text>
          <Text fontWeight="bold" color="tcpcyan.500">x {quantity}</Text>
          <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
           {productTags?.nodes?.map((item)=> (item.name))}
          </Text>
        </Stack>
      </Box>
    </Stack>
  )
}